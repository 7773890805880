import './index.css'

function App() {
  return (
    <div className="App">
      <h1>To anybody who's reading this!</h1>
      <h3>I pray that whatever is hurting you or whatever you are constantly stressing about gets better. May the dark thoughts, the overthinking, and the doubt exit your mind. May clarity replace confusion. May peace and calmness fill your life.</h3>
      <p>Thank yourself for how far you've come</p>
      <p>It hasn't been easy!</p>
      <i>Email: hello@viet.vn</i>
    </div>
  );
}

export default App;
